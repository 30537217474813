<template>
  <div class="laMiaScheda" style="height:100%;">
    <v-layout justify-center align-center style="height:100%;">
      <v-flex xs11 md8 lg5>
          <v-toolbar color="secondary">
              <v-toolbar-title class="white--text">
                  La mia scheda
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn small icon @click="isEditing = !isEditing" color="white" class="ml-3 mr-1">
                <v-icon small>fas fa-edit</v-icon>
              </v-btn>
          </v-toolbar>
          <v-card outlined>
              <v-form v-model="formValid" ref="form_data" lazy-formValidation>
                  <v-container>
                      <v-layout column>
                          <v-text-field
                              label="Nominativo 1"
                              v-model="nominativo1"
                              required
                              :readonly="!isEditing"
                              :rules="nominativo1Rules"
                          ></v-text-field>
                          <v-text-field
                              label="Nominativo 2"
                              v-model="nominativo2"
                              required
                              :readonly="!isEditing"
                              :rules="nominativo2Rules"
                          ></v-text-field>
                          <v-text-field
                              label="Codice Fiscale"
                              v-model="codiceFiscale"
                              :readonly="!isEditing"
                              :rules="codiceFiscaleRules"
                          ></v-text-field>
                          <v-text-field
                              label="Partita Iva"
                              v-model="partitaIva"
                              :readonly="!isEditing"
                              :rules="partitaIvaRules"
                          ></v-text-field>
                          <v-text-field
                              label="Telefono"
                              v-model="telefono"
                              required
                              :readonly="!isEditing"
                              :rules="telefonoRules"
                          ></v-text-field>
                          <v-text-field
                              label="Email"
                              v-model="email"
                              required
                              :readonly="!isEditing"
                              :rules="emailRules"
                          ></v-text-field>
                          <v-expansion-panels :value="0">
                            <v-expansion-panel>
                              <v-expansion-panel-header disable-icon-rotate>
                                Stabilimenti
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-list
                                  subheader
                                  two-line
                                >
                                  <v-list-item
                                    v-for="stabilimento of stabilimenti"
                                    :key="stabilimento._id"
                                  >
                                    <v-list-item-avatar>
                                      <v-icon
                                        class="grey lighten-1"
                                        dark
                                      >
                                        fas fa-building
                                      </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                      <v-list-item-title>{{ stabilimento.indirizzo }}</v-list-item-title>

                                      <v-list-item-subtitle>{{ stabilimento.note }}</v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                      <v-btn icon :to="'/infoStabilimento?_id='+stabilimento._id">
                                        <v-icon color="grey lighten-1">fas fa-eye</v-icon>
                                      </v-btn>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-list>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                      </v-layout>
                  </v-container>
              </v-form>
              <v-card-actions>
                <v-col cols="12" md="4" offset-md="4" sm="12" class="pt-0">
                  <v-btn color="primary" width="100%" @click="submit" :disabled="isEditing && !formValid && loading" v-text="isEditing ? 'Salva' : 'Indietro'"></v-btn> 
                </v-col>
              </v-card-actions>
          </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import apiStabilimenti from '@/js/pages/stabilimenti';
import apiUtenti from '@/js/pages/utenti';
import { emailRules, signupPasswordRules, nominativo1Rules, nominativo2Rules, telefonoRules, requiredRules, codiceFiscaleRules, partitaIvaRules } from '@/js/validationRules';

export default {
  name: 'laMiaScheda',
  data() {
    return {
      token: this.$cookies.get('token'),
      isEditing: false,
      email: '',
      telefono: '',
      nominativo1: '',
      nominativo2: '',
      codiceFiscale: '',
      partitaIva: '',
      password: '',
      formValid: false,
      emailRules: emailRules,
      passwordRules: signupPasswordRules,
      nominativo1Rules: nominativo1Rules,
      nominativo2Rules: nominativo2Rules,
      codiceFiscaleRules: codiceFiscaleRules,
      partitaIvaRules: partitaIvaRules,
      telefonoRules: telefonoRules,
      requiredRules: requiredRules,
      loading: false,
      stabilimenti: [],
      utente: {},
      id_utente: '',
      modalNewStabilimento: false,
    }
  },
  async created() {
    let utente = await apiUtenti.getUtenteFromToken(this.token);
    this.id_utente = utente._id || '';
    this.nominativo1 = utente.nominativo1 || '';
    this.nominativo2 = utente.nominativo2 || '';
    this.email = utente.email || '';
    this.partitaIva = utente.partitaIva || '';
    this.codiceFiscale = utente.codiceFiscale || '';
    this.telefono = utente.telefono || '';
    this.stabilimenti = await apiStabilimenti.getAll(this.token, utente._id);
  },
  methods: {
    async submit(){
      if(this.isEditing){
        this.loading = true;
        if(
          this.$refs.form_data.validate() &&
          this.formValid
        ){
          let data = {
            _id: this.id_utente,
            email: this.email,
            telefono: this.telefono,
            nominativo1: this.nominativo1,
            nominativo2: this.nominativo2,
            codiceFiscale: this.codiceFiscale,
            partitaIva: this.partitaIva,
          };
          await apiUtenti.updateMine(this.token, data);
          this.$router.back();
        }
        this.loading = false;
      } else this.$router.back();
    },
    async refreshListStabilimenti(stabilimento){
      this.modalNewStabilimento = false;
      this.stabilimenti.push(stabilimento);
    }
  },
}
</script>
